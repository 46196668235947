import getBasePath from '../../produto/common/functions/api-resource';

const basePath = getBasePath('planejamento_acao', 'adama/cadastro/rateio');

const acaoRateioProporcionalActions = {
  buscarRateiosProporcional: { methods: 'GET', url: `${basePath}/proporcao-faturamento?idNomeFantasia={idNomeFantasia}&idDivisao={idDivisao}{&idsContasCorrente*}` },
  buscarRateiosProporcionalCliente: { methods: 'GET', url: `${basePath}/cliente/{idAcao}?idDivisao={idDivisao}` },
  buscarRateiosProporcionalClienteComRateio: { methods: 'GET', url: `${basePath}/cliente/{idAcao}?indClientesComRateio={indClientesComRateio}` },
};

export default (resource) => resource(`${basePath}`, {}, acaoRateioProporcionalActions);
