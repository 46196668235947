<template lang="html">
  <v-tooltip bottom v-if="tiposImportacao.length">
    <template v-slot:activator="{ on, attrs }">
      <v-badge
          :content="contentBadge"
          overlap
          color="transparent"
          offset-x="22"
          offset-y="22"
         >
        <v-btn icon id="component_btn_cloud"
                v-bind="attrs"
                v-on="on"
                @click.native="goToImportPage">
          <v-icon id='component_icon_cloud' v-if="!done"
                  data-badge="2">
            {{ (quantidadeAndamento > 0) ? 'cloud_upload' : 'cloud_queue' }}
          </v-icon>
          <v-icon v-else>cloud_upload</v-icon>
        </v-btn>
      </v-badge>
    </template>
    <span>{{ $tc('label.importacao', 2) }}</span>
  </v-tooltip>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      progress: 0,
      progressInterval: null,
      done: false,
      transition: true,
    };
  },
  computed: {
    ...mapGetters('importacao', {
      importacoesAndamento: 'getImportacoesAndamento',
      tiposImportacao: 'getTiposImportacao',
    }),
    quantidadeAndamento() {
      return this.importacoesAndamento.length || 0;
    },
    contentBadge() {
      return (this.quantidadeAndamento > 9) ? '9+' : this.quantidadeAndamento;
    },
  },
  methods: {
    startProgress() {
      this.progressInterval = window.setInterval(() => {
        this.progress += 3;

        if (this.progress >= 115) {
          this.done = true;
          window.clearInterval(this.progressInterval);
          window.setTimeout(() => {
            this.done = false;
          }, 3E3);
        }
      }, 1E2);
    },
    restartProgress() {
      this.progress = 0;
      this.transition = false;
      this.done = false;

      window.clearInterval(this.progressInterval);
      window.setTimeout(() => {
        this.transition = true;
        this.startProgress();
      }, 600);
    },
    goToImportPage() {
      this.$emit('RESET_SIDEBAR');
      this.$router.push({ name: 'importacoes' });
    },
  },
};
</script>

<style src="./Badge.css" lang="css"></style>
